import React, {useLayoutEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import FilterSelectBox from "../../components/FilterSelectBox";
import FilterSearchBox from "../../components/FilterSearchBox";
import {TextField, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {IconButton, Menu, MenuItem} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DataTable from "../../components/DataTable";
import {PageSizeSelector} from "../../components/Pagination";
import DateTimeDialog from "../../components/DateTimeDialog";
import moment from "moment";
import {getEventHistoryStyled} from "./EventHistoryStyled";
import {useTranslation} from "react-i18next";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


/* 이력 화면 관련 공통 컴포넌트 -> 이력 페이지, 필터 기능, 테이블 데이터 정렬 */

/** 이력 페이지 필터 영역 및 데이터 테이블
 * @property selectOptionArray : 선택박스 필터
 * @property searchOptionArray : 사용자 입력박스 필터
 * @property filters : 사용하고 있는 필터
 * @property licenseOption : 라이선스 이력 조회 화면에서 라이선스 키로 검색
 * @property clientSearch : 클라이언트 검색은 직접 키워드를 입력하거나 선택박스 메뉴에서 고르면 된다
 * @property onSearch : 전체 검색 기능
 * @property onReset : 모든 필터링 조건 clear하기
 * @property onDeleteFilter : 필터 제거 (사용 중인 필터링 조건을 미사용으로 변경)
 * @property startDt : 검색 기간의 시작일
 * @property endDt : 검색 기간의 종료일
 * @property startErr : 시작일 필수 항목이오니 미입력 시 에러 발생 (boolean)
 * @property endErr : 종료일 필수 항목이오니 미입력 시 에러 발생 (boolean)
 * @property minDt : 정해진 기간(data range) 내에서 선택할 수 있는 min date
 * @property maxDt : 정해진 기간(data range) 내에서 선택할 수 있는 max date
 * @property onSubmitDate : 날짜 선택할 때 실행될 함수
 * @property table : 테이블 콘텐츠 (head & body 데이터, page, pageSize 등)
 **/
function HistoryPage(props) {

    const classes = getEventHistoryStyled();
    const {t} = useTranslation();
    const {selectOptionArray, searchOptionArray, filters, licenseOption, clientSearch, onSearch, onReset, onDeleteFilter,
        startDt, endDt, startErr, endErr, minDt, maxDt, onSubmitDate, table} = props;

    const [isOpenStart, setIsOpenStart] = useState(false);
    const [isOpenEnd, setIsOpenEnd] = useState(false);

    return (
        <section className={classes.root}>

            <Box className={classes.filters}>

                <Box display="grid">

                    {clientSearch &&
                        <ClientSearchBox {...clientSearch} />
                    }
                    {selectOptionArray.map((option) => (
                        <FilterSelectBox option={option} key={option.label}/>
                    ))}
                    {searchOptionArray.map((option) => (
                        <FilterSearchBox option={option} key={option.label}/>
                    ))}

                    <Box display='flex' flexDirection='column' className={classes.inputDate}>
                        <Typography>{t('UserPage.AuthHistory.StartDate')} <span>*</span></Typography>
                        <Button endIcon={<DateRangeIcon/>}
                                onClick={() => setIsOpenStart(true)}
                                disableRipple
                                className={!startErr ? classes.dateSelectBtn : `${classes.dateSelectBtn} ${classes.error}`}
                        >
                            {startDt}
                        </Button>
                    </Box>

                    <Box display='flex' flexDirection='column' className={classes.inputDate}>
                        <Typography>{t('UserPage.AuthHistory.EndDate')} <span>*</span></Typography>
                        <Button endIcon={<DateRangeIcon/>}
                                onClick={() => setIsOpenEnd(true)}
                                disableRipple
                                className={!endErr ? classes.dateSelectBtn : `${classes.dateSelectBtn} ${classes.error}`}
                        >
                            {endDt}
                        </Button>
                    </Box>

                </Box>

                {licenseOption &&
                    <FilterSearchBox option={licenseOption}
                                     key={licenseOption.label}
                                     rows={3}
                                     multiline={true}
                                     style={classes.licenseSearch}/>
                }

                <Box display="flex" justifyContent='space-between'>

                    <Box display='flex' columnGap='40px' flexWrap='wrap' alignItems='flex-start'>
                        {filters.length !== null &&
                            filters.map((keyword, idx) => (
                                <Box display='flex' key={idx} alignItems='center' className={classes.keywords}>
                                    <IconButton onClick={() => onDeleteFilter(idx, keyword.option)}
                                                className={classes.deleteIcon}
                                                sx={{right: 6}}
                                                disableRipple >
                                        <ClearIcon/>
                                    </IconButton>
                                    <Typography>{keyword.value}</Typography>
                                </Box>
                            ))
                        }
                    </Box>

                    <Box display="flex" className={classes.actionBtn}>
                        <Button onClick={onSearch}>{t('EventHistory.Search')}</Button>
                        <Button onClick={onReset}>{t('EventHistory.Reset')}</Button>
                    </Box>

                </Box>
            </Box>

            <Box className={classes.tableArea}>
                {table.data !== null &&
                    <DataTable headContent={table.head}
                               bodyContent={table.body}
                               currentPage={table.page + 1}
                               totalPage={table.data.page.totalPages}
                               oncClickRowEvent={table.rowClickEvent}
                               onChangePageEvent={table.pageChangeEvent}
                               emptyText={t('Error.NoData')}
                    />
                }
            </Box>

            <Box display='flex' className={classes.pageControl}>
                <PageSizeSelector pageSize={table.pageSize}
                                  onPageSelect={table.setPageSize}/>
            </Box>

            <DateTimeDialog open={isOpenStart}
                            dateValue={startDt}
                            minDate={minDt}
                            maxDate={endDt}
                            onClose={() => setIsOpenStart(false)}
                            onSubmit={(value) => onSubmitDate('start', value)} />

            <DateTimeDialog open={isOpenEnd}
                            dateValue={endDt}
                            minDate={startDt ? startDt : minDt}
                            maxDate={maxDt}
                            onClose={() => setIsOpenEnd(false)}
                            onSubmit={(value) => onSubmitDate('end', value)} />
        </section>
    )
}


function addFilter(startDt, endDt, deleteDates, clientName, deleteClientName, selectOptions, searchOptions, update) {
    let arr = [];

    if (startDt || endDt) {
        let date = '';
        if (startDt !== null && endDt === null) date = startDt + ' ~';
        if (endDt !== null && startDt === null) date = ' ~' + endDt;
        if (startDt !== null && endDt !== null) date = startDt + ' ~ ' + endDt;
        arr.push({label: 'date', value: date, option: {onChange: deleteDates}});
    }

    selectOptions.map((option) => {
        if (option.value !== 'ALL') {
            arr.push({value: option.items.find(x => x.value === option.value).text, option: option})
        }
    })
    searchOptions.map((option) => {
        if (option.value !== '') arr.push({value: option.value, option: option})
    })
    if (clientName && clientName !== '') {
        arr.push({value: clientName, option: {onChange: deleteClientName}})
    }
    update(arr);
}


function ClientSearchBox({clientPlaceholder, clientArray, onChangeClientName, onSelectClientName}) {

    const {t} = useTranslation();
    const classes = getEventHistoryStyled()
    const [clientMenu, setClientMenu] = useState(false);
    const text = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [fieldWidth, setFieldWidth] = useState(250);

    useLayoutEffect(() => {
        setAnchorEl(text.current);
        setFieldWidth(text.current.offsetWidth);
    }, []);

    return (
        <Box display='flex' flexDirection='column'>
            <Typography className={classes.filterLabel}>{t('EventHistory.Table.Client')}</Typography>
            <TextField value={clientPlaceholder}
                       placeholder={"ALL"}
                       ref={text}
                       className={classes.inputSearch}
                       variant="outlined"
                       onChange={(e) => onChangeClientName(e.target.value)}
                       onBlur={(e) => onChangeClientName(e.target.value.trim())}
                       InputProps={{
                           endAdornment:
                               <IconButton disableRipple onClick={() => setClientMenu(true)}>
                                   <ExpandMoreIcon/>
                               </IconButton>
                       }}
            />
            <Menu open={clientMenu} onClose={() => setClientMenu(false)}
                  className={classes.menuList}
                  anchorEl={anchorEl}
                  PaperProps={{
                      style: {
                          width: fieldWidth
                      }
                  }}
                  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                  }}>
                {clientArray.length > 0 && clientArray.map((client, idx) => (
                    <MenuItem key={idx} value={client.value}
                              onClick={() => onSelectClientName(client.value, client.text)}>{client.text}</MenuItem>
                ))}
                {clientArray.length === 0 &&
                    <MenuItem key={'no-data'} value={'empty'} onClick={() => setClientMenu(false)}>{t("Error.NoData")}</MenuItem>
                }
            </Menu>
        </Box>
    )
}


function TableHeadSort({sortType, setSortType}) {

    const {t} = useTranslation();
    const classes = getEventHistoryStyled();
    const isDesc = sortType === 'REG_DT,DESC';
    const isAsc = sortType === 'REG_DT,ASC';

    const onTableSort = () => {
        sortType === 'REG_DT,DESC' ? setSortType('REG_DT,ASC') : setSortType('REG_DT,DESC');
    }

    return (
        <div className={'timeSort'} onClick={onTableSort}>
            {t("EventHistory.Table.Time")}
            <Box className={classes.sortIcon}>
                <ExpandLessIcon aria-selected={isAsc}/>
                <ExpandMoreIcon aria-selected={isDesc}/>
            </Box>
        </div>
    )
}

function isEmpty(value) {
    return (value === 'ALL' || value === '') ? null : value;
}

function isDate(date) {
    return moment(date, 'yyyy-MM-DDTHH:mm:ss.sss', true).isValid()
}



export {HistoryPage, TableHeadSort, isEmpty, isDate, addFilter};