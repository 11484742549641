import i18n from 'i18next';
import {initReactI18next, useTranslation} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import theme from "./theme";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        ns: ['translation', 'gccs_translation', 'bsa_translation', 'fnsv_translation'],
        defaultNS: 'translation',   // useTranslation()에서 특정 namespace를 안 쓸때 기본 번역파일로 사용됨
        fallbackNS: ['translation', 'fnsv_translation'],
        react: {
            useSuspense: false,
        },
        lng: "ko",  // 기본 언어 설정
        fallbackLng: 'en',
        returnObjects: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        debug: false,
    });

export default i18n;

export function useCustomTranslation () {       // type에 따른 커스텀 번역 (필요한 단어가 없는 경우 fallbackNS로 넘어감)
    return theme.type === 'gccs' ? useTranslation('gccs_translation') : useTranslation('bsa_translation');
}